import Utility from "../class/utility";

const [ d, mq ] = [document, window.matchMedia( "(max-width: 900px)" ) ];

const domReady = TPL => false;

//共通
const jqInit = () => {
  //onlineshop用フッター
  $('.rwd002-block.is-head').before( $('.onlineshop-head') );
  $('.rwd002-pagetop__wrap').before( $('.rwd002-block.is-foot') );
  $.get('/src/html/footer-onlineshop.html').then( html => $('.rwd002-pagetop__wrap').before( html ) );
};

//詳細
const jqDetailInit = () => {
  const $detailHead = $('.onlineshop-detail-head')
  const $detailTitle = $('.onlineshop-detail-head__titleWrap');
  if( mq.matches ) $detailTitle.clone().prependTo( $detailHead );

  /*記事テンプレ内のソースを差し替え*/
  //info
  const $infoObj = $('.onlineshop-detail-head__info');
  const $infoObjDefault = $('.onlineshop-detail-head__info-default');
  $infoObj.length ? $infoObjDefault.replaceWith( $infoObj ) : $infoObjDefault.empty();
  
  //リード文
  const $leadObj = $('.onlineshop-detail-head__lead');
  const $leadObjDefault = $('.onlineshop-detail-head__lead-default');
  $leadObj.length ? $leadObjDefault.replaceWith( $leadObj ) : $leadObjDefault.empty();
  
  //SP時、サイドブロックを一番下に移動
  const $sideObj = $('.onlineshop-detail-head__side');
  if( mq.matches ) $('.onlineshop-detail__entry').after( $sideObj );
}


export default function ONLINESHOP () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => {
    jqInit()
    const UTIL = new Utility();
    if( UTIL.query_string('article') ) jqDetailInit();
  });
}