import LazyDisp from "../class/lazydisp";

const [ w, d ] = [window, document];

const domReady = TPL => false;

const jqInit = () => {
  // LazyDisp開始
  Promise.all([w.load['loadHtml']]).then(() => new LazyDisp('[data-lazydisp]'));
};

export default function SHOP () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}