import GET_ARTICLES from '../class/getArticles';
import Swiper from 'swiper/bundle';
import dayjs from 'dayjs';

const [ d ] = [document];

const domReady = TPL => {

  new Swiper('.mainvisual__slider', {
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 2000,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  });

  //ticker
  const ticker_block = new GET_ARTICLES('.block-ticker__article[data-plugin="getArticles"]');
  ticker_block.makeItem = (item,content) => `
    <a href="${item.href}" class="block-ticker__link">
      <figure class="block-ticker__img">
        <img src="${item.thumb}" alt="${item.title}" width="280">
      </figure>
      <div class="block-ticker__body">
        <div class="date-cate">
          <span class="date">${item.date}</span>
          <span class="cate">${item.category.name}</span>
        </div>
        <h4 class="subject">${item.title.str_truncate(30)}</h4>
      </div>
    </a>`;
  ticker_block.render();

  //ONLINESHOP
  const onlineshop_block = new GET_ARTICLES('.onlineshop-slider__wrap[data-plugin="getArticles"]');
  onlineshop_block.makeItem = (item,content) => `
  <a href="${item.href}" class="swiper-slide ${content}-slider-item">
    <figure class="${content}-slider-item__img">
      <img src="${item.thumb}" alt="${item.title}" width="280">
    </figure>
    <div class="${content}-slider-item__body">
      <h4 class="subject">${item.title.str_truncate(50)}</h4>
      <p class="desc">${item.caption.str_truncate(30)}</p>
    </div>
  </a>`;
  onlineshop_block.render();

  new Swiper('.onlineshop-slider', {
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 15,
    mousewheelControl : true,
    grabCursor: true,
    speed: 2000,
    navigation: {
      nextEl: ".onlineshop-slider__next",
      prevEl: ".onlineshop-slider__prev"
    },
    breakpoints: {
      901: {
        pagination: {
          el: ".swiper-pagination"
        },
        spaceBetween: 70,
        slidesPerGroup: 3,
        slidesPerView: 'auto',
        offsetPxAfter: 161,
      }
    }
  });




  //News
  const news_block = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
  news_block.makeItem = (item,content) => `
  <li class="block-${content}-item">
    <a href="${item.href}" class="block-${content}-item__link">
      <figure class="block-${content}-item__img">
        <img src="${item.thumb}" alt="${item.title}" width="280">
      </figure>
      <div class="block-${content}-item__body">
        <div class="date-cate">
          <span class="cate">${item.category.name}</span>
          <span class="date">${item.date}</span>
        </div>
        <h4 class="subject">${item.title.str_truncate(50)}</h4>
        <p class="desc">${item.desc.str_truncate(50)}</p>
      </div>
    </a>
  </li>`;
  news_block.render();

};

const jqInit = () => {

    //Works
    const works_block = new GET_ARTICLES('.works-slider__wrap[data-plugin="getArticles"]');
    works_block.makeItem = (item,content) => `
    <a href="${item.href}" class="swiper-slide ${content}-slider-item">
      <figure class="${content}-slider-item__img">
        <img src="${item.thumb}" alt="${item.title}" width="280">
      </figure>
      <div class="${content}-slider-item__body">
        <span class="cate">${item.category.name}</span>
        <h4 class="subject">${item.title.str_truncate(50)}</h4>
        <p class="desc">${item.desc.str_truncate(50)}</p>
      </div>
    </a>`;
    
    $.when( works_block.render() ).then(() => {
      const worksSlide = $('.works-slider-item');
      
      //スライド2枚以下のときはクローン
      if( worksSlide.length <= 2 ) {
        worksSlide.each((i, v) => $(v).clone().appendTo($('.works-slider__wrap')) );
      }
      
      new Swiper('.works-slider', {
        loop: true,
        speed: 6200,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 35,
        freeMode: true,
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        breakpoints: {
          901: {
            spaceBetween: 50,
          }
        }
      });
    });

  //創業何年か計算しテキストを置き換える
  $('.js-year', '.block-shop').each(( v ) => {
    const dateTo = dayjs('1967-10-01 00:00:00');
    const dateFrom = dayjs();
    const diff = dateFrom.diff(dateTo, 'year', false);
    $(v).text( diff + '年');
  });

};


export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}